import ColorSchemeSwitcher from '~/components/ColorSchemeSwitcher';
import styles from '~/components/Footer.module.scss';
import PartnerList from '~/components/PartnerList';
import SmartA from '~/components/SmartA';
import rootStyles from '~/components/Root.module.scss';
import SocialLinks from '~/components/SocialLinks';

export default function Footer() {
  return (
    <div class={styles.footer}>
      <div class={rootStyles.container}>
        <PartnerList />
        <hr />
        <ColorSchemeSwitcher />
        <SocialLinks />
        <p
          classList={{
            [styles.bylines]: true,
            [rootStyles['styled-links']]: true,
          }}
        >
          Rádio J-Hero v5.0 — Do seu jeito, do seu gosto!
          <br />
          Articles and logo are © 2008–{new Date().getFullYear()} Rádio J-Hero.
          All rights reserved.
          <br />
          All dates are displayed according to{' '}
          <SmartA rel="external" href="https://time.is/Brasília">
            Brasília Time
          </SmartA>
          .<br />
          Design by <SmartA href="/kuro">Elison</SmartA>. Icons by{' '}
          <SmartA rel="external" href="https://tabler.io/icons">
            Tabler
          </SmartA>
          . Powered by{' '}
          <SmartA rel="external" href="https://netlify.com/">
            Netlify
          </SmartA>
          .<br />
          <SmartA href="/privacidade">Política de Privacidade</SmartA>
        </p>
      </div>
    </div>
  );
}
